import styled, {css} from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  min-width: 100%;
  min-height: 100%;
  height: 100vh;
  gap: 2rem;
`;

export const Title = styled.div`
  text-align: center;
`;

export const BoxButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
`;

export const ButtonSelect = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 8rem;
  height: 8rem;
  align-items: center;
  gap: 1rem;
  border: 2px solid whitesmoke;
  background: #333333;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #F7FAFC;
`;
