import React, {useEffect} from 'react';
import {Container, LoginButtons, Title, Video} from "./styles";
import LoginGoogle from "../../components/LoginGoogle";
import EnterMock from "../../components/LoginMock";
import {useGame} from "../../context/GameContext";
import {IRouteParams} from "./interfaces"
import {useAuth} from "../../context/Auth";
import {useHistory, useParams} from "react-router-dom";
import Layout from "../../components/Layout";
import ReactPlayer from 'react-player/youtube'
import { APP_ENVIRONMENT as env } from '../../config/config'

const Login = ({match}: IRouteParams) => {
    const {id} = match.params

    const history = useHistory()
    const {logged} = useAuth()
    const {actions} = useGame()
    // const  {type}  = useParams();

    // useEffect(() => {
    //     if (logged) {
    //         history.push(URI.ENTER)
    //     }
    // },[])

    useEffect(() => {
        if (id) {
            sessionStorage.setItem('game_id', String(id))
            // let typeSel = '';
            // if (type != undefined) {
            //     typeSel = type;
            //
            // }
            // sessionStorage.setItem('type', typeSel)

        } else {
            sessionStorage.removeItem('game_id')
        }
    }, [id])

    return (
        <Layout>
            {/*<Loading />*/}
            <Container>
                <Title>
                    <h1>Middle Card</h1>
                    {/*<br />*/}
                    {/*<br />*/}
                    {/*<h3>Veja abaixo como e facil jogar</h3>*/}
                </Title>
                <LoginButtons onClick={() => {
                    actions.token()
                }}
                >
                    {/*<LoginGoogle/>*/}
                    {/*<LoginFacebook/>*/}

                    <EnterMock/>

                </LoginButtons>

                {/*<Video>*/}

                {/*    <ReactPlayer*/}
                {/*        url='https://youtube.com/shorts/LtbsqCvdaqg'*/}
                {/*        width={'auto'}*/}
                {/*        light={true}*/}
                {/*        pip={false}*/}
                {/*    />*/}

                {/*</Video>*/}

            </Container>
        </Layout>
    );
};

export default Login;
