import React from 'react';
import styled from "styled-components";


export const Container = styled.div`
  min-width: 100%;
  min-height: 100%;
  display: flex;
  //justify-content: center;
  //justify-items: center;
  flex-direction: column;
  height: 100vh;
  
  h2 {
    text-align: center;
    justify-self: center;
    max-width: 90%;
    max-height: 90%;
    padding-bottom: 20px;
  }
`;

export const TextInformation = styled.div`
  display: flex;
  box-shadow: 3px 4px 39px -3px #000000;
  border-radius: 1rem;
  max-width: 90%;
  max-height: 90%;
  text-align: justify;
  justify-self: center;
  align-self: center;
  background: #333333;
  border: 1px solid whitesmoke;
  line-height: 1.2rem;
  padding:10px;

  p {
    opacity: 0.8;
    font-weight: 400;
    font-size: 0.7rem;
  }

`;

const GameRule: React.FC = () => {
    return (
        <Container>
            <h2>Intruções</h2>
            <TextInformation>
                O jogo é simples e divertido, a cada nova rodada é descontado 25MC (MC = Moeda virtual do jogo) de cada
                jogador, para a banca da mesa, duas cartas são sorteadas aleatóriamente, e mostrado no mesa. Por
                exemplo, 4 jogadores entram na sala, a mesa estará com uma banca de 100₥¢. Caso o jogador ganhe, ele
                fica com a 100MC e o restante dos jogadores deverá pagar novamente 25MC,
                mas caso ele perda, deverá pagar o valor da banca, no caso 100₥¢, a banca estará com 200MC.
                O jogo finaliza quando o jogador ganha toda a mesa, e assim se inicia um novo jogo.x
            </TextInformation>
        </Container>
    );
};

export default GameRule;
