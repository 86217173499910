import styled from "styled-components"

interface ICardsProps {
    color: string
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 6rem;
  height: 9rem;
  background: whitesmoke;
  border-radius: 5px;
  padding-top: 0.2rem;
  border: 2px solid #000;
  opacity: 0.8;
  box-shadow: 3px 4px 39px -3px #000000;
`;

export const NumberSuit = styled.div<ICardsProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  font-size: 20px;
  font-weight: bold;
  color: ${(props => props.color)};
`;

export const BodySuit = styled.div<ICardsProps>`
  display: flex;
  justify-content: center;
  font-size: 2.5rem;
  color: ${(props => props.color)};
  padding-top: 5px;
`;

export const Suit = styled.span`
  text-decoration: none;
  font-size: 1rem;
`;
