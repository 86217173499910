import React from 'react';
import styled from "styled-components";
import {useGame} from "../../context/GameContext";


export const Container = styled.div`
  min-width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  justify-items: center;
  flex-direction: column;
  
  h2 {
    text-align: center;
    justify-self: center;
    max-width: 90%;
    max-height: 90%;
    padding-bottom: 20px;
  }
`;

export const TextInformation = styled.div`
  display: flex;
  padding: 10px;
  box-shadow: 3px 4px 39px -3px #000000;
  border-radius: 1rem;
  max-width: 90%;
  max-height: 90%;
  text-align: justify;
  justify-self: center;
  align-self: center;
  background: #333333;
  border: 1px solid whitesmoke;
  line-height: 1.2rem;

  p {
    opacity: 0.8;
    font-weight: 400;
    font-size: 0.7rem;
  }

`;

const Message: React.FC = () => {

    const { messageStatus } = useGame()

    return (
        <Container>
            <h2>Informação</h2>
            <TextInformation>
                {messageStatus}
            </TextInformation>
        </Container>
    );
};

export default Message;
