import React from 'react';
import {BodySuit, Container, NumberSuit, Suit} from "./styles";
import {ICard} from "../../types/games";

interface ICardProps {
    card: ICard
    showCard?: boolean
}

const Card: React.FC<ICardProps> = ({card}) => {

    return (
        <Container>
            <NumberSuit color={card.suit.color}>
                <span>{card.name}</span>
                <Suit>{card.suit.symbol}</Suit>
            </NumberSuit>
            <BodySuit color={card.suit.color}>
                {card.suit.symbol}
            </BodySuit>
        </Container>
    );
}

export default Card;
