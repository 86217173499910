import {WS_URL} from "./config";

class WsConn
{
    public ws: WebSocket = new WebSocket(String(WS_URL));
    public connectInterval: any;
    public timeout = 250;
    public iteration: number = 0;
    // @todo - add variable to check interation i.e clicks, keyboard, etc

    constructor() {

        this.connect();
    }

    connect() {
        if (this.iteration > 0)
            this.ws = new WebSocket(String(WS_URL));

        let that = this; // cache the this

        ++this.iteration;
        this.ws.onopen = (event) => {
            console.log('Connect WS')

            // setInterval(() => {
            //     let object = {
            //         route: '/ping',
            //         action: 'ping'
            //     }
            //
            //     let message = JSON.stringify(object);
            //     this.ws.send(message)
            // }, 2500); //call check function after timeout
        };

        // websocket onclose event listener
        this.ws.onclose = e => {
            // console.log(
            //     `Socket is closed. Reconnect will be attempted in ${Math.min(
            //         10000 / 1000,
            //         (that.timeout + that.timeout) / 1000
            //     )} second.`,
            //     e.reason
            // );
            //
            // this.timeout = this.timeout + this.timeout; //increment retry interval
            // this.connectInterval = setTimeout(() => {
            //     this.check()
            // }, Math.min(10000, that.timeout)); //call check function after timeout
        };
    }

    /**
     * utilited by the @function connect to check if the connection is close, if so attempts to reconnect
     */
    check()  {
        if (!this.ws || this.ws.readyState == WebSocket.CLOSED) {
            this.connect(); //check if websocket instance is closed, if so call `connect` function.
        }
    }

    configOnMessage(actions: any) {

        this.ws.onmessage = (messageStatus) => {
            actions.control(messageStatus, actions)
        }
    }

    async sendMessage(msg: any) {
        let message = JSON.stringify(msg);
        // @todo - add check time to send message.
        this.ws.send(message)
    }
}

export default WsConn
