import React, {createContext, useContext, useState} from 'react';
// import {encryptSessionToken} from "../security"; // @todo check export

interface IAuthContext {
    logged: boolean;
    signIn(token: string): void
    signOut(): void;
    setLogged(flag: boolean): void;
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

interface PropsDataAuth {
    children: React.ReactNode
}

const AuthProvider = ({ children }:PropsDataAuth) => {
    const [logged, setLogged] = useState<boolean>(false)

    const signIn = (token: string) => {
        //encryptSessionToken(token, '@tk')
        // console.log('signIn')
        setLogged(true)
    }

    const signOut = () => {
        setLogged(false);
    }

    return (
        <AuthContext.Provider value={
            {
                logged, signOut, signIn, setLogged
            }
        } >
            { children }
        </AuthContext.Provider>
    );
}

function useAuth(): IAuthContext {
    return useContext(AuthContext);
}

export { AuthProvider, useAuth };
