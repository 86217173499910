import React, {createContext, useContext, useEffect, useState} from 'react';
import {APP_URL} from "../config/config";
import URI from "../repository/route_names.json"

import {PropsData, IGameContext, IGameControl, IPlayer, IGame} from "../types/games";
import {useHistory} from "react-router-dom";

import Control from './actions/Control';
import WsConn from '../config/WsConn'

let wsConn = new WsConn();
const GameContext = createContext<IGameContext>({} as IGameContext)

const GameProvider = ({children}: PropsData) => {

    const history = useHistory()

    const initialGameState:any  = {
        game_id: 0,
        points: 0,
        accept: false,
        start: false,
        finish: false,
        is_creator: false,
        turn_start: false,
        turn_user: false,
        turn_result: false,
        turn_wait: false,
        turn_winner: false,
        turn_player_prev: "",
        turn_player: "",
        turn_accept: false,
        click_turn: false,
        num_players: 0,
        turn_default_seconds: 15,
        turn_seconds: 0,
    };

    const [gameControl, setGameControl] = useState<IGameControl>(initialGameState); // @todo review controls

    const [player, setPlayer] = useState<IPlayer>({id: 0,});
    const [players, setPlayers] = useState<IPlayer[]>([]);

    const [gameId, setGameId] = useState<number>(0);
    const [gameLink, setGameLink] = useState<string>('');
    const [messageStatus, setMessageStatus] = useState<string>('');
    const [stateTurnCard, setStateTurnCard] = useState(false);

    useEffect(() => {
        let path = window.location.pathname
        if (path != '/' && gameControl.finish) {
            /**
             * Para adicionar chamada para reconexão
             */
            // console.log(gameControl)
            ///window.location.href = '/enter'
        }
    }, [gameControl.finish])

    const actions = {

        control: Control,

        pingMessage: (data: any) => {

        },

        tokenMessage: (data: any) => {
            let token = actions.getToken()
            if (token) {
                sessionStorage.setItem('@mgc:account', "true")
            }

            sessionStorage.setItem('@mgc:token', data.result.token)
        },

        token: async () => {
            let object = {
                route: '/token',
                action: 'token'
            }

            await wsConn.sendMessage(object);
        },

        getToken: () => {
            return sessionStorage.getItem('@mgc:token')
        },

        finishGame: () => {
            //alert(document.URL.)
            //console.log('Saindo o jogo')
            history.push('/')

            // @todo store actions for close game
            // @todo add routines for stop all activities for a game

        },

        downConnectionGame: () => {

            console.log('action: downConnectionGame')
            setGameControl(
                {
                    ...gameControl,
                    turn_start: false,
                    finish: true
                }
            )
        },

        stopGameMessage: (data: any) => {
            let status = data.status

            if (status) {
                let statusGame = data.result.game_stop
                if (statusGame) {
                    setGameControl(
                        {
                            ...gameControl,
                            turn_start: false,
                            finish: true
                        }
                    )
                }
            }
        },

        stopGame: async () => {
            let sendData = {
                game_id: player.game_id
            }

            let object = {
                route: '/middle-cards/stop',
                action: 'stop-game',
                token: actions.getToken(),
                data: sendData
            }

            await wsConn.sendMessage(object)
        },

        exitGameMessage: (data: any) => {
            let status = data.status

            if (status) {
                setGameControl(
                    {
                        ...gameControl,
                        turn_start: false,
                        finish: true
                    }
                )
            }
        },

        exitGame: async () => {
            window.location.href = '/'
        },

        mountGameLink: (gameId: number) => {
            let linkGame = APP_URL + '/a/' + gameId
            setGameLink(linkGame)
        },

        resumeTurnsMessage: (data: any) => {
            let status = data.status
            let turns = data.result.turns

            if (status) {
                console.log(turns)
                alert('Olhe, veja bem')
            }
        },

        nextTurnMessage: (data: any) => {
            let status = data.status
            let turnPlayer = data.result.player

            if (status) {
                // Finish deck
                if (turnPlayer.cards === undefined) {
                    setGameControl(
                        {
                            ...gameControl,
                            finish: true,
                        }
                    )

                    return false;
                }

                let isUserTurn = (turnPlayer.id == player.id);
                let cardLeft = turnPlayer.cards[0];
                let cardRight = turnPlayer.cards[1];

                setGameControl(
                    {
                        ...gameControl,
                        start: true,
                        turn_start: true,
                        turn_seconds: gameControl.turn_default_seconds,
                        turn_user: isUserTurn,
                        turn_wait: false,
                        turn_player: turnPlayer.name,
                        turn_player_prev: turnPlayer.prev_player,
                        card_left: cardLeft,
                        card_right: cardRight,
                        click_turn: false
                    }
                )
            } else {
                console.log('O Jogador ' + turnPlayer.name + ' não possui créditos para continuar!')
                if (players.length == 2) {
                    console.log('Com dois jogadores e um sem créditos, o jogo é finalizado!')
                }
            }
        },

        nextTurn: async () => {
            let sendData = {
                game_id: player.game_id
            }

            let object = {
                route: '/middle-cards/next-turn',
                action: 'next-turn',
                token: actions.getToken(),
                data: sendData
            }

            await wsConn.sendMessage(object)
        },

        turnMessage: (data: any) => {
            let status = data.status

            if (status) {
                let cardResult = data.result.game.middle_card
                let isWinner = (data.result.game.is_winner == 1)
                let playerId = data.result.game.player_id
                let accept = data.result.game.accept
                let points = data.result.game.game_points

                if (accept && isWinner) {
                    console.log("O jogador " + gameControl.turn_player + " aceitou e venceu o turno")
                } else if (accept && !isWinner) {
                    console.log("O jogador " + gameControl.turn_player + " aceitou e perdeu o turno")
                } else if (!accept && !isWinner) {
                    console.log("O jogador " + gameControl.turn_player + " passou o turno")
                }

                setGameControl(
                    {
                        ...gameControl,
                        turn_start: false,
                        turn_user: false,
                        turn_wait: true,
                        turn_result: true,
                        turn_accept: accept,
                        turn_winner: isWinner,
                        middle_card: cardResult,
                        points: points,
                        click_turn: true
                    }
                )

                if (player.id == playerId) { // one call
                    actions.credits()
                }

                setStateTurnCard(true)
                setTimeout(async () => {
                    setStateTurnCard(false)
                    if (player.id == playerId) { // one call
                        await actions.nextTurn() // bug jack reported
                    }
                }, 3000)
            }
        },

        turn: async (accept: number) => {
            console.log(gameControl)
            let secondsTurn = 0
            if (gameControl.turn_seconds > 0) {
                secondsTurn = (gameControl.turn_seconds - gameControl.turn_default_seconds)
            }

            let sendData = {
                accept: accept,
                seconds: secondsTurn,
                game_id: player.game_id
            }

            let object = {
                route: '/middle-cards/turn',
                action: 'turn',
                token: actions.getToken(),
                data: sendData
            }

            await wsConn.sendMessage(object)
        },

        notifyGameCreditsMessage: (data: any) => {
            let gamePoints = data.result.game.points

            setGameControl(
                {
                    ...gameControl,
                    points: gamePoints,
                }
            )

        },

        notifyCreditsMessage: (data: any) => {
            let points = data.result.points
            let gamePoints = data.result.game_points
            let player = players[0]
            players[0].points = points
            setPlayers(players)

            setGameControl(
                {
                    ...gameControl,
                    points: gamePoints,
                }
            )
        },

        credits: async () => {
            let sendData = {
                game_id: player.game_id,
                player_id: player.id
            }

            let object = {
                route: '/middle-cards/notify-credits',
                action: 'notify-credits',
                token: actions.getToken(),
                data: sendData
            }

            await wsConn.sendMessage(object)
        },

        finishGamePlayers: async (gameId: number) => {
            let sendData = {
                game_id: gameId
            }

            let object = {
                route: '/middle-cards/finish-game-players',
                action: 'finish-game-players',
                token: actions.getToken(),
                data: sendData
            }

            await wsConn.sendMessage(object)
        },

        notifyGameConnectionsMessage: (data: any) => {
            let playersResult: any = data.result.players
            let playersList: any = []

            for (let i = 0; i < playersResult.length; ++i) {
                let currentPlayer = playersResult[i];
                playersList.push(currentPlayer)
            }

            if (playersList.length == 1) {
                setGameControl(
                    {
                        ...gameControl,
                        num_players: playersList.length,
                        turn_start: false,
                        finish: true
                    }
                )

                actions.finishGamePlayers(gameControl.game_id)
            } else {
                setGameControl(
                    {
                        ...gameControl,
                        num_players: playersList.length
                    }
                )
            }

            setPlayers(playersList)
        },

        notifyGameMessage: (data: any) => {

            let playersResult: any = data.result.players
            let playersList: any = players

            // check player in array
            const isInArray = (value: any, array: any) => {
                let len = array.length
                for (let i = 0; i < len; ++i) {
                    if (value.id == array[i].id) {
                        return true;
                    }
                }
                return false;
            }

            for (let i = 0; i < playersResult.length; ++i) {
                let currentPlayer = playersResult[i];
                if (!isInArray(currentPlayer, playersList)) {
                    playersList.push(currentPlayer)
                }
            }

            setGameControl(
                {
                    ...gameControl,
                    num_players: playersList.length
                }
            )

            setPlayers(playersList)
        },

        enterGameMessage: (data: any) => {
            let status = data.status
            let playerData = data.result.player

            if (status) {
                if (player) {
                    actions.mountGameLink(playerData.game_id)
                    let gamePoints = Number(data.result.game.points)
                    setGameControl(
                        {
                            ...gameControl,
                            points: gamePoints
                        }
                    )

                    setPlayer(
                        {
                            id: playerData.id,
                            game_id: playerData.game_id,
                            points: playerData.points
                        }
                    )

                    player.id = playerData.id
                    player.points = playerData.points
                    player.game_id = playerData.game_id
                    players.push(player)
                    setPlayers(players)
                }
                //history.push("/deck")
            } else {
                // @todo add message via backend
                setMessageStatus('Jogo já iniciado. Aguarde um novo jogo')
                history.push("/message")
            }
        },

        enterGame: async (gameId: number, playerData: IPlayer) => {
            let object = {
                route: '/middle-cards/enter',
                action: 'enter-game',
                token: actions.getToken(),
                data: playerData
            }

            await wsConn.sendMessage(object)
        },

        notifyStartGameMessage: (data: any) => {
            let status = data.status

            if (status) {
                let turnPlayer = data.result.player
                let isUserTurn = (turnPlayer.id == player.id)
                let cardLeft = data.result.player.cards[0]
                let cardRight = data.result.player.cards[1]
                let pointsGame = data.result.points

                setGameControl(
                    {
                        ...gameControl,
                        start: true,
                        turn_start: true,
                        turn_seconds: gameControl.turn_default_seconds,
                        turn_user: isUserTurn,
                        turn_wait: false,
                        turn_player: turnPlayer.name,
                        card_left: cardLeft,
                        card_right: cardRight,
                        points: pointsGame
                    }
                )
            }
        },

        startGameMessage: (data: any) => {
            let status = data.status

            if (status) {
                setGameControl(
                    {
                        ...gameControl,
                        start: true
                    }
                )
            }
        },

        startGame: async () => {
            let object = {
                route: '/middle-cards/start',
                action: 'start-game',
                token: actions.getToken(),
                data: {
                    game_id: gameId
                }
            }
            await wsConn.sendMessage(object)
        },

        createGameMessage: (data: any) => {

            let status = data.status
            if (status) {
                let gamePoints = Number(data.result.game.points)
                let gameId = Number(data.result.game.id)
                let playerPoints = Number(data.result.player.points)

                setGameId(gameId)

                actions.mountGameLink(gameId)

                if (player) {

                    player.game_id = gameId
                    player.points = playerPoints
                    player.is_creator = false
                    player.key = ""

                    players.push(player)
                    setPlayers(players)

                    setGameControl(
                        {
                            ...gameControl,
                            game_id: data.result.game.id,
                            points: gamePoints,
                            is_creator: true,
                            num_players: 1
                        }
                    )
                }
                history.push(URI.DECK)
            }
        },

        createGame: async () => {
            // @todo check player object, verify email player
            let object = {
                route: '/middle-cards',
                action: 'create-game',
                token: actions.getToken(),
                data: player
            }
            await wsConn.sendMessage(object)
        },

        accountMessage: (data: any) => {

            if (data.status) {
                let playerId: number = data.result.player.id
                let points: number = data.result.player.points
                setPlayer(
                    {
                        ...player,
                        id: playerId,
                        points: points
                    }
                )
            }

        },

        account: async (playerData: IPlayer) => {

            let object = {
                route: '/middle-cards/account',
                action: 'account',
                token: actions.getToken(),
                data: playerData
            }
            await wsConn.sendMessage(object)
        },

        check: () => {
            console.log('Actions - GameProvider');
        }
    }

    wsConn.configOnMessage(actions);

    return (
        <GameContext.Provider
            value={
                {
                    gameId,
                    gameLink,
                    actions,
                    player,
                    setPlayer,
                    gameControl,
                    setGameControl,
                    players,
                    setPlayers,
                    messageStatus,
                    stateTurnCard
                }
            }>
            {children}
        </GameContext.Provider>
    );
};

const useGame = (): IGameContext => {
    return useContext(GameContext)
}

export {GameProvider, useGame}
