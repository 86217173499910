import React from 'react';
import {Container} from "./styles";

type FrameMotion = {
    rotateY?: number
    duration?: number
    display?: string
}

interface ICardDefault {
    frameMotion?: FrameMotion
}

const CardDefault: React.FC<ICardDefault> = (
    {
        children,
        frameMotion,
    }) => {

    return (
        <Container>
            {children}
        </Container>
    );
};

export default CardDefault;
