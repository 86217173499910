import styled, {keyframes} from "styled-components";
import {Coins} from '@styled-icons/fa-solid/Coins'
import {PanelBottom} from '@styled-icons/fluentui-system-filled/PanelBottom'
import {ArrowLeftShort} from '@styled-icons/bootstrap/ArrowLeftShort'
import {ArrowRightShort} from '@styled-icons/bootstrap/ArrowRightShort'
import {CoinStack} from '@styled-icons/boxicons-solid/CoinStack'
import {ShareAlt} from '@styled-icons/boxicons-solid/ShareAlt'
import {Whatsapp} from '@styled-icons/boxicons-logos/Whatsapp'
import {Play} from '@styled-icons/fa-solid/Play'

export const WhatsappButton = styled(Whatsapp)`
  color: whitesmoke;
  opacity: 0.7;
  width: 4rem;
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, .7));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, .7));
`;


export const ShareButton = styled(ShareAlt)`
  color: whitesmoke;
  opacity: 0.7;
  width: 3.5rem;
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, .7));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, .7));
`;

export const IconPlay = styled(Play)`
  color: whitesmoke;
  opacity: 0.7;
  width: 3.5rem;
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, .7));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, .7));
`;

export const BetCoins = styled(Coins)`
  color: whitesmoke;
  opacity: 0.7;
  width: 1.5rem;
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, .7));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, .7));
`;

export const Pass = styled(PanelBottom)`
  color: whitesmoke;
  opacity: 0.7;
  width: 1.5rem;
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, .7));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, .7));
`;

export const ArrowLeft = styled(ArrowLeftShort)`
  color: whitesmoke;
  opacity: 0.7;
  width: 2rem;
`;

export const ArrowRight = styled(ArrowRightShort)`
  color: whitesmoke;
  opacity: 0.7;
  width: 2rem;
`;

export const CoinSymbol = styled(CoinStack)`
  color: whitesmoke;
  width: 2rem;
`;

interface IArea {
    area: string;
    justify?: string
}

interface IAnimation {
    param: string;
}


export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 0.2fr 0.6fr 1fr 1fr;

  gap: 0;
  grid-template-areas: 
          "STATUS STATUS STATUS"
          "PLAYERONE PLAYERTWO PLAYERTREE"
          "CARD CARD CARD"
          "B1 PLAYER B2";
  width: 100%;
  height: 100vh;

  @media screen and (min-width: 568px) {
    grid-template-areas:
          "STATUS STATUS STATUS"
          ". PLAYERONE ."
          "PLAYERTWO CARD PLAYERTREE"
          "B1 PLAYER B2";
  }
`;

export const Status = styled.div`
  display: grid;
  grid-area: STATUS;
`;

export const AreaPlayer = styled.div<IArea>`
  display: flex;
  grid-area: ${(props => props.area)};
  justify-content: center;
  align-items: center;
`;

export const AreaCards = styled.div`
  display: grid;
  grid-area: CARD;
  //gap: 1rem;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: repeat(2, max-content);
  grid-template-areas: 
            "information information information"
            "cardLeft cardResult cardRight";
  align-self: center;
  width: 100%;
  justify-items: center;

  @media screen and (min-width: 568px) {
    grid-template-columns: repeat(3, auto);
    grid-template-rows: repeat(2, max-content);
    grid-template-areas: 
            "information information information"
            "cardLeft cardResult cardRight";
    column-gap: 1.5rem;
    width: unset;
  }
`;

export const InformationDeck = styled.div`
  grid-area: information;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  font-weight: 500;
  text-align: center;
  height:110px;
`;

const breatheAnimation = keyframes`
  0% {
    transform: rotate(-540deg) scale(0);
    opacity: 0;
  }
  100% {
    transform: rotate(0) scale(1);
    opacity: 1;
  }
`

export const CardLeft = styled.div`
  display: grid;
  grid-area: cardLeft;
  justify-self: self-end;
  animation-name: ${breatheAnimation};
  animation-duration: 1s;
`;


export const CardRight = styled.div`
  display: grid;
  grid-area: cardRight;
  justify-self: self-start;
  animation-name: ${breatheAnimation};
  animation-duration: 1s;
`;

export const CardResult = styled.div`
  display: grid;
  grid-area: cardResult;
  justify-content: center;
  animation-name: ${breatheAnimation};
  animation-duration: 1s;
`;

export const AreaButton = styled.div<IArea>`
  display: flex;
  flex-direction: row;
  grid-area: ${(props => props.area)};
  justify-self: ${(props => props.justify)};
  align-items: center;
  color: white;
  justify-content: center;
  gap: 10px;
`;

export const Button = styled.div`
  display: flex;
  width: 3.7rem;
  height: 3.7rem;
  font-size: 4rem;
  text-align: center;
`;

export const AvatarPlayer = styled.img`

  margin-bottom: 10px;
  display: flex;
  border-radius: 15%;
  width: 4rem;
  border: 2px solid whitesmoke;
  color: #fff;
  text-align: center;
  justify-content: center;
  align-items: center;
  justify-self: center;
  font-weight: bold;
  box-shadow: 0 10px 14px -7px #0c120a;
  text-shadow: 0 1px 0 #5b8a3c;
`;


export const DetailsPayer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-weight: 700;
  align-items: center;

  @media screen and (max-width: 568px) {
    flex-direction: column;
    justify-content: space-around;
    gap: 0.7rem;
  }
`;


export const CreditPlayer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 600;
`;
export const PlayerCoins = styled.div`

`;

interface IStatusGame {
    area: string
}

export const StatusGame = styled.div<IStatusGame>`
  grid-area: ${props => props.area};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  label {
    font-size: 1rem;
    opacity: 0.9;
    margin-top: 5px;
  }
`;

export const AvatarTotal = styled.div`
  display: flex;
  background: #333333;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  color: whitesmoke;
  text-align: center;
  justify-content: center;
  align-items: center;

`;

interface IActionButtons {
    color: string
    width?: number
    height?: number
}

export const ActionButtons = styled.div<IActionButtons>`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  width: ${(props => props.width +'rem')};//5rem
  height: ${(props => props.height+'rem')};//3.4rem;
  border: 2px solid whitesmoke;
  color: #fff;
  text-align: center;
  justify-content: center;
  align-items: center;
  justify-self: center;
  font-weight: bold;
  box-shadow: 0 10px 14px -7px #0c120a;
  text-shadow: 0 1px 0 #5b8a3c;
  cursor:pointer;
  background: ${(props => props.color)}
`;

export const ShareButtons = styled.div<IActionButtons>`
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  width: 3rem;
  height: 3rem;
  border-width: 1px;
  color: #fff;
  text-align: center;
  justify-content: center;
  align-items: center;
  justify-self: center;
  font-weight: bold;
  box-shadow: 0 10px 14px -7px #0c120a;
  text-shadow: 0 1px 0 #5b8a3c;
  cursor:pointer;
  background: ${(props => props.color)}
`;

export const ExitButton = styled.div<IActionButtons>`
  display: flex;
  border-radius: 15%;
  width: 2.5rem;
  height: 1.5rem;
  border-width: 1px;
  color: #fff;
  text-align: center;
  justify-content: center;
  align-items: center;
  justify-self: center;
  font-weight: bold;
  box-shadow: 0 10px 14px -7px #0c120a;
  text-shadow: 0 1px 0 #5b8a3c;
  cursor:pointer;
  background: ${(props => props.color)}
`;


export const Information = styled.div`
  grid-area: CARD;
  display: flex;
  max-width: 80%;
  flex-direction: column;
  gap: 10px;
  //box-shadow: 3px 4px 39px -3px #000000;
  justify-self: center;
  align-self: center;
  font-size: 0.9rem;
  @media screen and (max-width: 568px) {
    //max-width: 80%;
    //max-height: 100%;
  }
`;


export const NoPlayer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    font-size: 0.7rem;
  }
`;


export const TextInformation = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 10px;
  font-weight: 700;
  
  span {
    font-size: 0.8rem;
    cursor:pointer;
  }
  span:active {
    background-color:#EFEFEF;
    font-color:#000;
  }
`;

export const InitialButton = styled.div`
  display: flex;
  align-items:center;
  justify-content:center;
  border: 1px solid;
  border-radius: 5px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  gap: 10px;
  padding:10px;
  height:40px;
  cursor:pointer;
  background:darkgreen;
`;

export const ButtonBet = styled.div`
  display: flex;
  background: #333333;
  height: 3rem;
  width: 3rem;
  border: 1px solid #f8f6f6;
  border-radius: 50%;

  text-align: center;
  justify-content: center;
  align-items: center;
`;


export const InfoActions = styled.div`
  grid-area: PLAYER;
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  justify-content: center;
  @media screen and (min-width: 568px) {
    flex-direction: row;
    width: unset;
    justify-content: space-around;
  }

`;

export const DeckInformation = styled.div`
  grid-area: infoUser;
  display: flex;
  align-items: center;
`;


export const Label = styled.label`
    
`;


