import React, {useEffect} from 'react';
import {Container, CreateButton, CreateGame, Exit, GridOptions, Header, Info } from "./styles";
import {useGame} from "../../context/GameContext";
import {useHistory} from "react-router-dom";
import URI from "../../repository/route_names.json"
import {useAuth} from "../../context/Auth";

const Payment: React.FC = () => {
    const history = useHistory()
    const {signOut: signOutApp } = useAuth()

    const { actions, player} = useGame()
    //const mp = new MercadoPago("TEST-f8fa4e11-baf2-4da4-9e8d-4e6455f78139");

    return (
        <Container>
            <Header>
                <Info>
                    <p><b>Olá, nOME, selecione um opção</b></p>
                </Info>
            </Header>
            <GridOptions>
                {/* <script src="https://sdk.mercadopago.com/js/v2"></script> */}

                <CreateGame>
                    <form action="/process_payment" method="post" id="paymentForm">
                        <h3>Forma de Pagamento</h3>
                        <div>
                            <select className="form-control" id="paymentMethod" name="paymentMethod">
                                <option>Selecione uma forma de pagamento</option>

                                {/* Create an option for each payment method with their name and complete the ID in the attribute 'value'. */}
                                <option value="--PaymentId--">--PaymentTypeName--</option>
                            </select>
                        </div>
                        <h3>Detalhe do comprador</h3>
                        <div>
                            <div>
                                <label htmlFor="payerFirstName">Nome</label>
                                <input id="payerFirstName" name="payerFirstName" type="text" value="Nome" />
                            </div>
                            <div>
                                <label htmlFor="payerLastName">Sobrenome</label>
                                <input id="payerLastName" name="payerLastName" type="text" value="Sobrenome" />
                            </div>
                            <div>
                                <label htmlFor="payerEmail">E-mail</label>
                                <input id="payerEmail" name="payerEmail" type="text" value="test@test.com" />
                            </div>
                            <div>
                                <label htmlFor="docType">Tipo de documento</label>
                                <select id="docType" name="docType" data-checkout="docType">
                                    <option value={1}>Option</option>
                                </select>
                            </div>
                            <div>
                                <label htmlFor="docNumber">Número do documento</label>
                                <input id="docNumber" name="docNumber" data-checkout="docNumber" type="text" />
                            </div>
                        </div>

                        <div>
                            <div>
                                <input type="hidden" name="transactionAmount" id="transactionAmount" value="100" />
                                    <input type="hidden" name="productDescription" id="productDescription"
                                           value="Nome do Produto" />
                                        <br/>
                                            <button type="submit">Pagar</button>
                                            <br/>
                            </div>
                        </div>
                    </form>
                </CreateGame>
            </GridOptions>
        </Container>
    );
};

export default Payment;
