import React from 'react';
import {Container} from "./styles";
import {useGame} from "../../context/GameContext";
import {useAuth} from "../../context/Auth";
import URI from "../../repository/route_names.json"
import {useHistory} from "react-router-dom";

import AvatarJorge from "../../assets/images/jorginho.jpg"
import AvatarSpider from "../../assets/images/spider.jpg"
import AvatarJonas from "../../assets/images/jonas.png"
import AvatarFrida from "../../assets/images/frida.png"
import AvatarNerd from "../../assets/images/nerd.png"
import AvatarElvis from "../../assets/images/elvis.png"
import AvatarRaul from "../../assets/images/raul.png"

const LoginMock = () => {
    const history = useHistory()
    const { setLogged } = useAuth()
    const {actions, setPlayer} = useGame()

    const loginEnter = () => {
        let gameId = sessionStorage.getItem('game_id')
        let type   = sessionStorage.getItem('type')

        let mockUsers = [
            { name: "Jorginho", email: 'annonymous.player@middlecard.com', avatar: AvatarJorge, game_id: 0},
            { name: "Raul", email: 'annonymous.player@middlecard.com', avatar: AvatarRaul, game_id: 0},
            { name: "Elvis", email: 'annonymous.player@middlecard.com', avatar: AvatarElvis, game_id: 0},
            { name: "Spider", email: 'annonymous.player@middlecard.com', avatar: AvatarSpider, game_id: 0},
            { name: "Jonas", email: 'annonymous.player@middlecard.com', avatar: AvatarJonas, game_id: 0},
            { name: "Frida", email: 'annonymous.player@middlecard.com', avatar: AvatarFrida, game_id: 0},
            { name: "Nerd", email: 'annonymous.player@middlecard.com', avatar: AvatarNerd, game_id: 0},
        ]

        let playerData: { name: string; email: string;  avatar: string; game_id: number }
        setLogged(true)

        if (gameId) {
            let userIndex = getRandomArbitrary(1, mockUsers.length)
            let user = mockUsers[userIndex]

            playerData = {
                email: user.email,
                name: user.name,
                avatar: user.avatar,
                game_id: Number(gameId)
            }

            setPlayer(playerData)
            actions.enterGame(Number(gameId), playerData);

            history.push(URI.DECK)
            return true

        } else {
            let user = mockUsers[0]

            playerData = {
                email: user.email,
                name: user.name,
                avatar: user.avatar,
                game_id: 0
            }
            setPlayer(playerData)
            actions.account(playerData); // #account
            history.push(URI.ENTER)
            return true
        }
    }

    const getRandomArbitrary = (min: number, max: number) => {
        return Math.floor(Math.random() * (max - min)) + min;
    }

    return (
        <Container>
            <button onClick={() => setTimeout(loginEnter, 1500) }>Login Anônimo</button>
        </Container>
    );
};

export default LoginMock;
