import React from "react";
import Routes from "../src/routes";
import GlobalStyles from "./styles/GlobalStyles";
import {BrowserRouter} from "react-router-dom";
import {GameProvider} from "./context/GameContext";
import {APP_GTM} from "./config/config";
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'

const App = () => {

    if (APP_GTM !== '') {
        const gtmParams:any = {
            id: APP_GTM
        }

        return (
            <GTMProvider state={gtmParams}>
                <BrowserRouter>
                    <GlobalStyles/>
                    <GameProvider>
                        <Routes/>
                    </GameProvider>
                </BrowserRouter>
            </GTMProvider>
        )
    }

    return (
        <>
            <BrowserRouter>
                <GlobalStyles/>
                <GameProvider>
                    <Routes/>
                </GameProvider>
            </BrowserRouter>
        </>
    )
};
export default App;
