import {createGlobalStyle} from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    @import url('https://fonts.googleapis.com/css2?family=Comic+Neue:ital,wght@0,300;1,700&display=swap"');
  }
    body {
      margin: 0!important;
      padding: 0!important;
    }
    
    #root {
      height: 100%;
      color: whitesmoke;
      background: radial-gradient(#519D44, #333333) no-repeat;
    }

    *, button, input {
      border: 0;
      outline: 0;
      font-family: 'Comic Neue', cursive;
    }

    button {
      cursor: pointer;
    }
  
`;
