import React from 'react';
import {Grid, Container, Header, Footer, SuitCard, Copyright, Version} from "./styles";
import Diamond from "../../assets/images/diamond.svg"
import Spade from "../../assets/images/spade.svg"
import Club from "../../assets/images/club.svg"
import Heart from "../../assets/images/heart.svg"

const Layout: React.FC = ({children}) => {
    return (
        <Grid>
            <Header>
                <SuitCard src={Club} alt={''} />
                <Version>
                   Version Apha 0.0.1
                </Version>
                <SuitCard src={Diamond} alt={''}/>
            </Header>
            <Container>
                {children}
            </Container>
            <Footer>
                <SuitCard src={Spade} alt={''}/>
                <Copyright>&#169; 2024 BY ADSW. ALL RIGHTS RESERVED </Copyright>
                <SuitCard src={Heart} alt={''}/>
            </Footer>
        </Grid>
    );
};

export default Layout;
