import React from 'react';
import {Switch, Route} from 'react-router-dom'
import Login from "../pages/Login";
import { useAuth } from "../context/Auth";

const AppRoutes = () => {
    const { logged } = useAuth()
    return (
        <Switch>

            { !logged && <Route path="/" exact component={Login} />  }

            <Route path='/a/:id' exact component={Login} />

            <Route path='/s/:id/:type/' exact component={Login} />

        </Switch>
    );
};

export default AppRoutes;
