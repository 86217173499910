import React from 'react';

import { useAuth } from "../context/Auth"

import App from "./app.routes";
import Auth from "./auth.routes";

const Routes: React.FC = () => {
    const { logged } = useAuth();

    return (
        <>
            { logged ? <Auth/> : <App/> }
        </>


    )};

export default Routes;
