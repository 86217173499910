import styled, {css} from "styled-components";
import {FaStripeS} from "react-icons/all";

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: 1fr 6fr;
  grid-template-areas: 
          "HEADER"
          "OPTIONS";
  min-width: 100%;
  min-height: 100%;
  height: 100vh;

  @media screen and (min-width: 568px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, max-content);
    grid-template-areas: 
          "HEADER"
          "OPTIONS";
    height: 100vh;
    align-content: center;
  }
  
`;

export const Header = styled.header`
  display: flex;
  height: 6rem;
  flex-direction: row;
  grid-area: HEADER;
  text-align: center;
  justify-content: center;
  align-self: baseline;
  margin-bottom: 40px;
  border-radius: 0.5rem;
  padding: 10px;

  p {
    font-size: 1rem;
    padding-bottom: 5px;
  }

  img {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    border: 2px solid whitesmoke;
    box-shadow: 3px 4px 39px -3px #000000;
    -webkit-box-shadow: 3px 4px 39px -3px #000000;
  }
`;

export const TitleRoom = styled.div`
  margin-bottom: 30px;
  border-radius: 0.5rem;
  border: 0.1rem solid whitesmoke;
  width: 80%;
  justify-self: center;
  box-shadow: 3px 4px 39px -3px #000000;
  -webkit-box-shadow: 3px 4px 39px -3px #000000;
`;

export const Info = styled.div`
  display: flex;
  height: auto;
  align-self: center;
  flex-direction: column;
  opacity: 0.7;

  p {

    font-size: 1.5rem;
  }
`;


export const GridOptions = styled.div`
  grid-area: OPTIONS;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  @media screen and (min-width: 568px) {
    justify-content: center;
  }
`;
export const Rules = styled.div`
  
  margin-bottom: 30px;
  border-radius: 0.5rem;
  border: 0.1rem solid whitesmoke;
  width: 80%;
  height: 3rem;
  justify-self: center;
  box-shadow: 3px 4px 39px -3px #000000;
  -webkit-box-shadow: 3px 4px 39px -3px #000000;
`;

export const CreateGame = styled.div`
  
  margin-bottom: 30px;
  border-radius: 0.5rem;
  border: 0.1rem solid whitesmoke;
  width: 80%;
  height: 3rem;
  justify-self: center;
  box-shadow: 3px 4px 39px -3px #000000;
  -webkit-box-shadow: 3px 4px 39px -3px #000000;
`;

export const Exit = styled.div`
 
  margin-bottom: 30px;
  border-radius: 0.5rem;
  border: 0.1rem solid whitesmoke;
  width: 80%;
  height: 3rem;
  justify-self: center;
  box-shadow: 3px 4px 39px -3px #000000;
  -webkit-box-shadow: 3px 4px 39px -3px #000000;
`;

export const RoomsDetails = styled.div`
  grid-area: ROOMS;
  border-radius: 0.5rem;

  background: transparent;
  align-self: baseline;

`;

export const CreateButton = styled.button`
  border-radius: 5px;
  background: transparent;
  width: 100%;
  height: 100%;
  font-size: 1.4rem;
  font-weight: 500;
  color: whitesmoke;
  opacity: 0.7;
  -webkit-box-shadow: 3px 4px 39px -3px #ffffff;
  box-shadow: 3px 4px 39px -3px #000000;
`;

interface IBoxRoom {
    status?: string
}

export const BoxRoom = styled.div<IBoxRoom>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  border-radius: 5px;
  background: transparent;
  margin-bottom: 10px;
  padding: 20px;
  justify-content: space-between;
  -webkit-box-shadow: 3px 4px 39px -3px #000000;
  box-shadow: 3px 4px 39px -3px #000000;

  ${({status}) =>
          status == 'closed' &&
          css`
            opacity: 0.5;
            cursor: not-allowed;
            background: #4e121b;
          `}

  ${({status}) =>
          status == 'opened' &&
          css`
            opacity: 1;
            //background: #124e20;
            cursor: pointer;
          `}

}
`;

export const GridRoom = styled.div`
  display: grid;
`;


export const RoomName = styled.p`

`;