import styled from "styled-components";


export const Container = styled.div`
  display: flex;
  justify-content: center;
  button {
    display: flex;
    justify-content: center;
    width: 13rem;
    height: 40px;
    align-items: center;
    gap: 1rem;
    border: 1px solid #F7FAFC;
    //background: radial-gradient(#519D44, #333333) no-repeat;
    background: #333333;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    text-align: center;

    color: #F7FAFC;

    i {
      font-size: 1rem;
    }
  }
`;
