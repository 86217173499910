
export default function Control(message: any, obj: any) {
    const data = JSON.parse(message.data);
    // @todo check action in message
    if (data.action === undefined) {
        console.log(data)
        return false
    }
    let actionName = getActionFunction(data.action)
    if (typeof (obj[actionName]) == 'function') {
        obj[actionName](data)
        return true;
    }
    console.log('Function for return - ' + actionName + ' - is not defined!');
    return false;
}

function getActionFunction(action: string) {
    let funcPars = action.split('-')
    let parts = funcPars.length
    let actionName = ''
    for (let i = 0; i < parts; ++i) {
        if (i > 0) {
            funcPars[i] = capitalizeFirstLetter(funcPars[i])
        }
        actionName += funcPars[i]
    }
    return actionName + 'Message'
}

function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
