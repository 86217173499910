import React, {FC, useEffect} from 'react';
import {
    ActionButtons,
    AreaButton,
    AreaCards,
    AreaPlayer,
    AvatarPlayer,
    CardLeft,
    CardResult,
    CardRight,
    Container,
    CreditPlayer,
    DetailsPayer,
    IconPlay,
    InfoActions,
    Information,
    InformationDeck,
    Label,
    ShareButton,
    Status,
    TextInformation,
    WhatsappButton
} from "./styles";
import Pass from '../../assets/images/pass.svg'
import Go from '../../assets/images/go.svg'

import CardDefault from "../../components/CardDefault";
import Card from "../../components/Card";

import {useGame} from "../../context/GameContext";
import CountUp from "react-countup";
import Layout from "../../components/Layout";

const Deck: FC = () => {
    const {actions, gameLink, gameControl, players, stateTurnCard, setGameControl} = useGame()
    let interval: any = null;

    function copyToClipboard(textToCopy: string) {
        // navigator clipboard api needs a secure context (https)
        if (navigator.clipboard && window.isSecureContext) {
            // navigator clipboard api method'
            return navigator.clipboard.writeText(textToCopy);
        } else {
            // text area method
            let textArea = document.createElement("textarea");
            textArea.value = textToCopy;
            // make the textarea out of viewport
            textArea.style.position = "fixed";
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            return new Promise<void>((res, rej) => {
                // here the magic happens
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
            });
        }
    }

    const copyLink = async (link: string) => {
        await copyToClipboard(link)
    }

    const linkWhats = 'https://wa.me/?text=' + gameLink
    useEffect(() => {
        /*
            Checar a chamada entre um jogador e outro.
        */
        if (gameControl.turn_start) {
            interval = setInterval(() => {
                if (gameControl.turn_seconds == 0) {
                    if (gameControl.click_turn) { // prevent double click
                        return false;
                    }
                    clearInterval(interval)

                    let option = calcChanges(gameControl.card_left.number,
                        gameControl.card_right.number, false)
                    actions.turn(option)
                    return true;
                }

                setGameControl(
                    {
                        ...gameControl,
                        turn_seconds: gameControl.turn_seconds - 1
                    }
                )
            }, 1000);
        }

        return () => clearInterval(interval as NodeJS.Timeout);

    }, [gameControl.turn_start, gameControl.turn_seconds])

    const getRandomArbitrary = (min: number, max: number) => {
        return Math.floor(Math.random() * (max - min)) + min;
    }

    const calcChanges = (cardLeft: number, cardRight: number, optionDefault: boolean) => {

        if (optionDefault) {
            return 1;
        }

        if (cardLeft == cardRight) // exception
            return 0

        let diff
        let limit
        let randOption = 0
        if (cardLeft > cardRight) {
            diff = cardLeft - cardRight
            limit = cardLeft
        } else {
            diff = cardRight - cardLeft
            limit = cardRight
        }

        if (diff < 2) { // exception
            return 0
        }

        if (diff < 5) {
            let tmp = diff
            while (tmp > 0) {
                randOption = getRandomArbitrary(diff, limit + diff)
                --tmp
            }
        } else {
            randOption = getRandomArbitrary(diff, limit + diff)
        }

        return (randOption % 2) ? 1 : 0
    }

    const formatName = (name: any) => {
        let tmpName = name.split(' ');
        return tmpName[0]
    }

    return (
        <Layout>
            <Container>
                <Status>
                    {gameControl.start && gameControl.is_creator ?
                        <AreaButton area={'CARD'}>
                            <ActionButtons
                                onClick={() => {
                                    actions.stopGame()
                                }}
                                color={'#C44536'}
                                width={3}
                                height={2}
                            >
                                Parar
                            </ActionButtons>
                        </AreaButton>
                        :
                        ''
                    }

                    {gameControl.start && !gameControl.is_creator ?
                        <AreaButton area={'CARD'}>
                            <ActionButtons
                                onClick={() => {
                                    actions.exitGame()
                                }}
                                color={'#C44536'}
                                width={3}
                                height={2}
                            >
                                Sair
                            </ActionButtons>
                        </AreaButton>
                        :
                        ''
                    }

                </Status>
                {!gameControl.start && gameControl.is_creator ?
                    <Information>
                        <TextInformation>

                            {(gameControl.num_players) < 2 ?
                                <h2>
                                    <label>
                                        Você precisa no minímo de dois jogadores
                                        para iniciar a partida!
                                    </label>
                                </h2>
                                :
                                ''
                            }

                            <br/>
                            <label onClick={() => copyLink(gameLink)}>{gameLink}</label>
                            <h2>Convide seus amigos</h2>
                            <AreaButton area={'CARD'}>
                                {
                                    (gameControl.num_players) > 1 ?
                                        <ActionButtons
                                            onClick={() => {
                                                actions.startGame()
                                            }}
                                            color={'#333333'}
                                            width={4}
                                            height={4}
                                        >
                                            <IconPlay/>
                                        </ActionButtons>
                                        :
                                        ''
                                }
                                <ActionButtons
                                    onClick={() => copyLink(gameLink)}
                                    color={'#333333'}
                                    width={4}
                                    height={4}
                                >
                                    <ShareButton/>
                                </ActionButtons>
                                <ActionButtons
                                    onClick={() => window.open(linkWhats, "_blank", "share/whatsapp/share")}

                                    color={'#333333'}
                                    width={4}
                                    height={4}
                                >
                                    <WhatsappButton/>
                                </ActionButtons>
                                <br/>
                                <br/>

                            </AreaButton>
                        </TextInformation>
                    </Information>
                    :
                    ''
                }

                {!gameControl.start && !gameControl.is_creator ?
                    <Information>
                        <TextInformation>
                            <h2><label>Aguarde o início da partida!</label></h2>
                        </TextInformation>
                    </Information>
                    :
                    ''
                }

                {gameControl.start && !gameControl.finish ?
                    <AreaCards>
                        <InformationDeck>




                            {!gameControl.turn_wait ?
                                <h2><Label>
                                    {gameControl.turn_user ?
                                        'Você tem ' :
                                        formatName(gameControl.turn_player) + " tem "}
                                    <strong>{gameControl.turn_seconds}</strong> segundos <br/>
                                    para aceitar ou passar.
                                    <br/>
                                    <br/>
                                </Label>
                                </h2>
                                :
                                <h2>
                                    <Label>
                                        {gameControl.turn_winner && gameControl.turn_accept ?
                                            <div>{formatName(gameControl.turn_player)} venceu!!!</div>
                                            :
                                            ''
                                        }

                                        {!gameControl.turn_winner && !gameControl.turn_accept ?
                                            <div>{formatName(gameControl.turn_player)} passou!!!</div>
                                            :
                                            ''
                                        }

                                        {!gameControl.turn_winner && gameControl.turn_accept ?
                                            <div>{formatName(gameControl.turn_player)} perdeu!!!</div>
                                            :
                                            ''
                                        }

                                        <br/>
                                        <div>Aguardando próximo turno...</div>

                                    </Label>
                                </h2>
                            }

                        </InformationDeck>

                        {gameControl.card_left ?
                            <CardLeft key={gameControl.card_left.number}>
                                <Card
                                    card={gameControl.card_left}
                                />
                            </CardLeft> : ''
                        }

                        {
                            stateTurnCard ?
                                <CardResult key={gameControl.middle_card}>
                                    <Card
                                        card={gameControl.middle_card}
                                    />
                                </CardResult> :
                                <CardDefault>
                                    <h3>Valendo</h3>
                                        <h1>
                                            <CountUp
                                                end={gameControl.points}
                                                duration={1}
                                            />
                                        </h1>
                                        <h3>créditos</h3>
                                </CardDefault>
                        }

                        {gameControl.card_right ?
                            <CardRight key={gameControl.card_right.number}>
                                <Card
                                    card={gameControl.card_right}
                                />
                            </CardRight> : ''
                        }

                    </AreaCards>
                    :
                    ''
                }

                {gameControl.start && gameControl.finish ?
                    <AreaCards>
                        <InformationDeck>
                            <label>
                                Jogo finalizado!
                            </label>

                        </InformationDeck>

                    </AreaCards>
                    :
                    ''
                }

                {players && players[2] ?
                    <AreaPlayer area={'PLAYERTWO'}>
                        {
                            players && players[2] &&
                          <DetailsPayer>
                            <AvatarPlayer src={players[2].avatar}/>
                              {
                                  players ? formatName(players[2].name) : ''
                              }
                            <label>{players && players[2].points}</label>
                              {players[2].points}
                          </DetailsPayer>
                        }
                    </AreaPlayer>
                    : ''
                }

                {players && players[1] ?
                    <AreaPlayer area={'PLAYERONE'}>
                        {
                            players && players[1] &&
                            <DetailsPayer>
                                <AvatarPlayer src={players[1].avatar}/>
                                {
                                    players ? formatName(players[1].name) : ''
                                }
                                <label>{players && players[1].points}</label>
                                {players[1].points}
                            </DetailsPayer>
                        }
                    </AreaPlayer>
                    : ''
                }

                {players && players[3] ?
                    <AreaPlayer area={'PLAYERTREE'}>
                        {
                            players && players[3] &&
                          <DetailsPayer>
                            <AvatarPlayer src={players[3].avatar}/>
                              {
                                  players ? formatName(players[3].name) : ''
                              }
                            <label>{players && players[3].points}</label>
                              {players[3].points}
                          </DetailsPayer>
                        }
                    </AreaPlayer>
                    : ''
                }

                {players && players[0] ?
                    <InfoActions>
                        <DetailsPayer>
                            <AvatarPlayer src={players[0].avatar}/>

                            {formatName(players[0].name)}
                            <CreditPlayer>
                                <CountUp
                                    end={Number(players[0].points)}
                                    duration={1}
                                />
                                <label>Créditos</label>
                            </CreditPlayer>
                        </DetailsPayer>

                    </InfoActions>
                    : ''
                }

                {gameControl.turn_user && gameControl.turn_start && !gameControl.finish && (
                    <>
                        <AreaButton area={'B2'} justify={'self-start'}>
                            <ActionButtons
                                color={"#519D44"}
                                onClick={() => {
                                    actions.turn(1)
                                }}
                                width={4.5}
                                height={4.5}
                            >
                                <img src={Go} alt={""} />
                            </ActionButtons>
                        </AreaButton>

                        <AreaButton area={'B1'} justify={'self-end'}>
                            <ActionButtons
                                onClick={() => {
                                    actions.turn(0)
                                }}
                                color={'#C44536'}
                                width={4.5}
                                height={4.5}
                            >
                                <img src={Pass} alt={""} />
                            </ActionButtons>
                        </AreaButton>
                    </>
                )}
            </Container>
        </Layout>
    );
};

export default Deck;
