import React from 'react';
import {BoxButtons, ButtonSelect, Container, Title } from "./styles";
import {useGame} from "../../context/GameContext";
import {useHistory} from "react-router-dom";
import URI from "../../repository/route_names.json"
import {useAuth} from "../../context/Auth";
import {useGoogleLogout} from 'react-google-login';
import Layout from "../../components/Layout";

import {IconRooms, IconHelp, IconSuggest, IconArrow} from './repository'

const Enter = () => {
    const history = useHistory()
    const {signOut: signOutApp} = useAuth()

    const {actions, player} = useGame()
    const keyGoogle = "656881409138-15i8hv02ucs5uc4gkektk0jord7duihq.apps.googleusercontent.com"

    function handleLogoutSuccess() {
        signOutApp()
        history.push(URI.LOGIN)
    }

    function handleLogoutFailure() {
        console.log('Fail')
    }

    const {signOut: signOutGoogle} = useGoogleLogout({
        clientId: keyGoogle,
        onLogoutSuccess: handleLogoutSuccess,
        onFailure: handleLogoutFailure,
    });

    return (
        <Layout>
            <Container>
                <Title>
                    <h1>Middle Card</h1>
                </Title>
                <br />
                <br />
                <BoxButtons>
                    <ButtonSelect onClick={() => {
                        actions.createGame()
                    }}>
                        Jogar
                        <img src={IconArrow} />
                    </ButtonSelect>
                    <ButtonSelect>
                        Sugestoes
                        <img src={IconSuggest} />
                    </ButtonSelect>
                    {/*<ButtonSelect>*/}
                    {/*    Salas*/}
                    {/*    <img src={IconRooms} />*/}
                    {/*</ButtonSelect>*/}
                    <ButtonSelect onClick={() => {
                        history.push(URI.HELP)
                    }}>
                        Ajuda
                        <img src={IconHelp} />
                    </ButtonSelect>
                </BoxButtons>

            </Container>
        </Layout>
    );
};

export default Enter;
