import styled from "styled-components";

export const Grid = styled.div`
  display: grid;
  grid-template-rows: 6% 86% 7%;

  gap: 0;
  grid-template-areas: 
          "Header"
          "Container"
          "Footer";
  width: 100%;
  height: 100vh;
`;

export const Header = styled.div`
  grid-area: Header;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
`;


export const Container = styled.div`
  grid-area: Container;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Footer = styled.div`
  grid-area: Footer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  padding: 13px;
`;

export const SuitCard = styled.img`
  opacity: 70%;
`;

export const Copyright = styled.b`
  display: flex;
  font-size: 80%;
  opacity: 70%;
`;

export const Version = styled.b`
  display: flex;
  font-size: 80%;
  opacity: 70%;
`;

