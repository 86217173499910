import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  min-width: 100%;
  min-height: 100%;
  height: 100vh;
  gap: 2rem;
`;

export const Video = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  border: 3px solid white;
  border-radius: 4px;
  margin: 0 22%;
`;


export const Title = styled.div`
  text-align: center;
`;

export const LoginButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

