export const API_URL = process.env.REACT_APP_API_HOST
export const APP_KEY = process.env.REACT_APY_KEY
export const APP_KEY_STORAGE = process.env.REACT_APP_KEY_STORAGE
export const APP_KEY_OAuth = process.env.REACT_APP_KEY_OAuth
export const APP_URL = process.env.REACT_APP_APP_HOST;
export const WS_URL = process.env.REACT_APP_WS_HOST;
export const APP_KEY_GOOGLE_LOGIN = process.env.REACT_APP_GOOGLE_LOGIN;
export const APP_KEY_FACEBOOK_LOGIN = process.env.REACT_APP_FACEBOOK_LOGIN;
export const APP_ENVIRONMENT=process.env.REACT_APP_ENVIRONMENT;
export const APP_GTM=process.env.REACT_APP_GTM;
const API_KEY = process.env.REACT_APP_API_KEY;

export const headerAuth = () =>  {
    let token = sessionStorage.getItem('@mgc:token');
    return { headers: {"Authorization" : "Bearer  " + token} };
}

export const config  = {
    headers: {
        'App-Key': API_KEY,
        'Content-Type': 'application/json'
    }};

export const data = {
    username: 'app-access',
    password: 'Ch@M4sTer!)@(#'
}
