import styled from "styled-components";


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 6rem;
  height: 9rem;
  background: #333333;
  border-radius: 5px;
  padding-top: 0.2rem;
  border: 2px solid whitesmoke;
  color: white;

  text-align: center;
  justify-content: center;
  box-shadow: 3px 4px 39px -3px #000000;

  p {
    opacity: 0.7;
    font-size: 5rem;
  }

  label {
    font-size: 0.8rem;
  }
`;
