import React from 'react';
import {Route, Switch} from "react-router-dom";
import {useAuth} from "../context/Auth";
import Enter from "../pages/Enter";
import Deck from "../pages/Deck";
import Payment from "../pages/Payment";
import GameRule from "../pages/GameRules";
import Login from "../pages/Login";
import Message from "../pages/Message";

const AuthRoutes = () => {
    const { logged } = useAuth();
    return (
        <Switch>
            { !logged && <Route path="/" exact component={Login} />  }
            <Route path="/enter" component={Enter} />
            {/*<Route path="/rooms" component={Rooms}/>*/}
            <Route path="/deck" component={Deck} />
            <Route path="/payment" component={Payment} />
            <Route path="/help" component={GameRule} />
            <Route path="/message" component={Message} />
        </Switch>
    );

};
export default AuthRoutes;
